import { render, staticRenderFns } from "./ReadRecords.vue?vue&type=template&id=164e4042&scoped=true"
import script from "./ReadRecords.vue?vue&type=script&lang=js"
export * from "./ReadRecords.vue?vue&type=script&lang=js"
import style0 from "./ReadRecords.vue?vue&type=style&index=0&id=164e4042&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "164e4042",
  null
  
)

export default component.exports